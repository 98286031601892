import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { externalServices }) =>
    fromState.featureAdapter.setAll(
      externalServices.map((e) => ({ ...e.external_services, ...e })),
      {
        ...state,
        isLoading: false,
        error: null,
      },
    ),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadDetailsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadDetailsSuccess, (state, { externalService }) =>
    fromState.featureAdapter.addOne(
      { ...externalService },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    ),
  ),
  on(fromActions.loadDetailsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.attachRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.attachSuccess, (state, { externalService }) =>
    fromState.featureAdapter.addOne(
      { ...externalService.external_services, ...externalService },
      {
        ...state,
        isLoading: false,
        error: null,
      },
    ),
  ),
  on(fromActions.attachFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.detachRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.detachSuccess, (state, { externalServiceId }) =>
    fromState.featureAdapter.removeOne(externalServiceId, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.detachFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function propertyExternalServicesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
