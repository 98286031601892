import { createAction, props } from '@ngrx/store';

import {
  AttachExternalServiceRequest,
  ExternalService,
  UpdateExternalServiceRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Property External Services] Load Request',
  props<{ propertyId: number; isAdmin: boolean }>(),
);

export const loadSuccess = createAction(
  '[Property External Services] Load Success',
  props<{ externalServices: ExternalService[] }>(),
);

export const loadFailure = createAction(
  '[Property External Services] Load Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Property External Services] Load Details Request',
  props<{
    propertyId: number;
    externalServiceId: number;
    isAdmin: boolean;
  }>(),
);

export const loadDetailsSuccess = createAction(
  '[Property External Services] Load Details Success',
  props<{ externalService: ExternalService }>(),
);

export const loadDetailsFailure = createAction(
  '[Property External Services] Load Details Failure',
  props<{ error: any }>(),
);

export const attachRequest = createAction(
  '[Property External Services] Attach Request',
  props<{
    request: AttachExternalServiceRequest;
  }>(),
);

export const attachSuccess = createAction(
  '[Property External Services] Attach Success',
  props<{ externalService: ExternalService }>(),
);

export const attachFailure = createAction(
  '[Property External Services] Attach Failure',
  props<{ error: any }>(),
);

export const detachRequest = createAction(
  '[Property External Services] Detach Request',
  props<{ propertyId: number; externalServiceId: number }>(),
);

export const detachSuccess = createAction(
  '[Property External Services] Detach Success',
  props<{ externalServiceId: number }>(),
);

export const detachFailure = createAction(
  '[Property External Services] Detach Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Property External Services] Update Request',
  props<{ request: UpdateExternalServiceRequest }>(),
);

export const updateSuccess = createAction(
  '[Property External Services] Update Success',
);

export const updateFailure = createAction(
  '[Property External Services] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Property External Services] Reset State',
);
