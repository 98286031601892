import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';

import { ExternalService, ExternalServiceType } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getAllItemsGroupedByProperty = (state: State) =>
  groupBy(Object.values(state.entities), 'property_id');

export const selectState = createFeatureSelector<State>(
  'propertyExternalServices',
);

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: number) =>
  createSelector(selectAllItems, (externalServices: ExternalService[]) => {
    return (
      externalServices &&
      externalServices.find((p) => p.external_service_id === +id)
    );
  });

export const selectByType = (type: ExternalServiceType) =>
  createSelector(selectAllItems, (externalServices: ExternalService[]) => {
    return (
      externalServices &&
      externalServices.filter((p) => p.service_type === type)
    );
  });

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectAllItemsGroupedByProperty = createSelector(
  selectState,
  getAllItemsGroupedByProperty,
);
