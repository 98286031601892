import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, flatMap, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { PropertyExternalServicesService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class PropertyExternalServicesStoreEffects {
  constructor(
    private dataService: PropertyExternalServicesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      flatMap(({ propertyId, isAdmin }) =>
        this.dataService.load(propertyId, isAdmin).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              externalServices: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ externalServiceId, propertyId, isAdmin }) =>
        this.dataService
          .loadDetails(propertyId, externalServiceId, isAdmin)
          .pipe(
            map((response: IResponseSuccess) =>
              fromActions.loadDetailsSuccess({
                externalService: response.data[0],
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadDetailsFailure(error));
            }),
          ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request }) =>
        this.dataService.update(request).pipe(
          map(() => {
            this.notifications.updateSuccess('external_service');
            return fromActions.updateSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  attach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.attachRequest),
      switchMap(({ request }) =>
        this.dataService.attach(request).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.createSuccess('external_service');
            return fromActions.attachSuccess({
              externalService: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.attachFailure(error));
          }),
        ),
      ),
    ),
  );

  detach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.detachRequest),
      switchMap(({ propertyId, externalServiceId }) =>
        this.dataService.detach(propertyId, externalServiceId).pipe(
          map(() => {
            this.notifications.deleteSuccess('external_service');
            return fromActions.detachSuccess({ externalServiceId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.detachFailure(error));
          }),
        ),
      ),
    ),
  );
}
